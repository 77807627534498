import { lazy } from "solid-js";
import { render } from "solid-js/web";

import { Redirect, Router, RouterConfig } from "~/components/Router";
import { getToken } from "~/service/Auth";

const CONFIG: RouterConfig = {
    routes: [
        { path: "/", Component: lazy(() => import("~/pages/Home")) },
        { path: "/create-room", Component: lazy(() => import("~/pages/CreateRoom")), guard: () => getToken().loggedIn },
        { path: "/player", Component: lazy(() => import("~/pages/Player")), guard: () => getToken().loggedIn },
    ],
    NotFound: lazy(() => import("~/pages/NotFound")),
    GuardFail: () => <Redirect to="/" />,
};

async function main() {
    const result = await fetch("/api/v1/spotify/check_token");

    if (result.ok) {
        const needsReload = await result.json();

        if (needsReload) {
            console.warn("Reloading due to refreshed auth cookie");
            location.reload();
        }
    }

    render(() => <Router config={ CONFIG } />, document.getElementById("root")!);
}

main();
