import { Component, For, Match, ParentProps, Show, Switch } from "solid-js";

function buildHref(to: string): string {
    const slash = to[0] !== "/" ? "/" : "";

    return `${location.origin}${slash}${to}`;
}

/**
 * Link component. Can be used instead of an `a` tag to let the user move around the app without refreshing the page.
 * @param to Pathname to go to.
 */
export const Link: Component<ParentProps<{ to: string, class?: string }>> = (props) => {
    const handle = (e: any) => {
        e.preventDefault();
        location.href = buildHref(props.to);
    };

    return <a href={ props.to } class={ props.class } onClick={ handle }>{ props.children }</a>;
};

interface Route {
    path: string;
    Component: Component<any>;
    guard?: () => boolean;
}

/**
 * Config object for a router.
 */
export interface RouterConfig {
    routes: Route[];
    NotFound: Component;
    GuardFail: Component;
}

/**
 * Router component.
 *
 * Pass in a configuration object with all your routes in it and it'll do the magic for you!
 */
export const Router: Component<{ config: RouterConfig }> = (props) => {
    const NotFound = props.config.NotFound;

    return (
        <Switch fallback={ <NotFound /> }>
            <For each={ props.config.routes }>
                { ({ path, Component, guard }) => (
                    <Match when={ location.pathname === path }>
                        <Show when={ guard ? guard() : true } fallback={ <props.config.GuardFail /> }>
                            <Component />
                        </Show>
                    </Match>
                ) }
            </For>
        </Switch>
    );
};

/**
 * Redirect the user to another page.
 *
 * @param to Url to redirect to
 */
export function redirect(to: string) {
    location.href = buildHref(to);
}

export const Redirect: Component<{ to: string }> = (props) => {
    location.href = buildHref(props.to);

    return <></>;
};
