export interface AccessToken {
    user_id: string;
    token: string;
    expires: unknown;
    refresh_token: string;
    scope: string;
}

type LoginState = { loggedIn: false } | { loggedIn: true, token: AccessToken };

/** Source: https://www.w3schools.com/js/js_cookies.asp */
function getCookie(cname: string): string {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export function getToken(): LoginState {
    const cookie = getCookie("SPA");

    return cookie
        ? { loggedIn: true, token: JSON.parse(window.atob(cookie)) }
        : { loggedIn: false };
}
